#cart-panel{
    background-color: #a1deb4;
    height: 100vh;
    width: 300px;
    overflow: scroll;
    flex-grow: 0; 
    flex-shrink: 0;
}

.cart-item-container{
    display: flex;
    align-items: center;
}
