.plant-browser-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 80vw;
    margin: auto;
}

.plant-browser-item {
    width: 150px;
    height: 150px;
    margin: 20px;
    border-radius: 10%;
    background-size: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.plant-browser-item:hover .plant-browser-item-cart{
    visibility: visible;
}

.plant-browser-item-infos{
    width: fit-content;
    padding: 7px;
    background-color: #68c586;
    color: azure;
    text-transform: capitalize;
    justify-self: flex-start;
    text-align: center;
}

.plant-browser-item-cart{
    visibility: hidden;
    width: fit-content;
    padding: 7px;
    background-color: #68c586;
    color: azure;
    text-transform: capitalize;
    transform: translateY(-10px);
}

.plant-browser-item-cart:hover{
    cursor: pointer;
    margin: -2px;
    border: 2px solid white;
}


.remove-item-cart{
    position: relative;
    top: 2px;
}

.container-item-cart-remove{
    position: absolute;
    background-color: rgb(255, 76, 76);
    color: white;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    transform: translateY(-5px) translateX(-70px);
    text-align: center;
    cursor: pointer;
}

.number-item-cart-controller{
    display: flex;
    justify-content: center;
    text-align: center;
    vertical-align: middle;

    padding-bottom: 5px;
}

.minus-item-cart, .plus-item-cart {
    width: 15px;
    height: 20px;
    background-color: rgb(220, 220, 220);
    border: 1px solid white;
    bottom: 10px;
    cursor: pointer;
}

.number-item-cart {
    background-color: white;
    width: 35px;
    height: 20px;
    border: 1px solid white;
}



